  ul.pagination {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  ul.pagination li.page-item.active a.page-link {
    color: #116ec5 !important;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
  }
  ul.pagination a.page-link {
    padding: 0.5rem 0.9rem;
    min-width: 1rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: #6b88a4;
    font-weight: 600;
    font-size: 0.9rem;
  }
  ul.pagination a.page-link:hover {
    background-color: #f4f4f4;
  }